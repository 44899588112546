import React from 'react'
import '../styles/main.css' // Ensure correct path
import backgroundImage from '../assets/background.webp' // Adjust path as needed

const Main = ({ language }) => {
  return (
    <main className='main'>
      <div className='background'>
        <img
          src={backgroundImage}
          alt='Background'
          className='background-image'
        />
      </div>
      <div className='content'>
        <h1>{language === 'en' ? 'Introducing CapCam' : 'CapCam'}</h1>
        <h2>
          {language === 'en'
            ? 'Capture Reality into Metaverse.'
            : '口袋中的3D扫描仪'}
        </h2>
        <a
          href={
            language === 'en'
              ? 'https://apps.apple.com/us/app/capcam-3d-camera-scanner/id6737015770?referrer=capcam.app'
              : 'https://apps.apple.com/cn/app/capcam-三维lidar扫描仪/id6737015770?referrer=capcam.app'
          }
        >
          <button>{language === 'en' ? 'Try CapCam' : '试用CapCam'}</button>
        </a>
      </div>
    </main>
  )
}

export default Main
